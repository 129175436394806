/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import {
  ErrorMessage,
  FormBase,
  FormLabel,
  FormInput,
  FormButton,
} from "./shared.js";

export const Edit = (props) => {
  let navigate = useNavigate();

  const { username } = useParams();
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [city, setCity] = useState("");
  let [email, setEmail] = useState("");

  let [error, setError] = useState("");

  const onSubmit = async (ev) => {
    ev.preventDefault();
    // Only proceed if there are no errors

    if (error !== "") return;
    const res = await fetch("/v1/user", {
      method: "PUT",
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        city: city,
      }),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
    if (res.ok) {
      navigate("/profile/" + username);
    } else {
      const err = await res.json();
      setError(err.error);
    }
  };

  useEffect(() => {
    if (username !== props.currentUser) {
      navigate("/profile/" + props.currentUser);
    }

    const fetchInfo = async () => {
      try {
        const response = await fetch("/v1/user/" + username, {
          method: "GET",
        });

        const data = await response.json();

        setFirstName(data.first_name);
        setLastName(data.last_name);
        setCity(data.city);
        setEmail(data.primary_email);
      } catch (err) {
        console.error("Fetch error");
      }
    };

    fetchInfo();
  }, []);

  return (
    <div style={{ gridArea: "main" }}>
      <h2 style={{ textAlign: "center" }}>Edit Profile</h2>
      <ErrorMessage msg={error} />
      <FormBase>
        <FormLabel htmlFor="username">Username:</FormLabel>
        <FormInput
          id="username"
          name="username"
          type="text"
          placeholder="Username"
          value={username}
          disabled
        />

        <FormLabel htmlFor="firstname">First Name:</FormLabel>
        <FormInput
          id="firstname"
          name="firstname"
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(ev) => setFirstName(ev.target.value)}
        />

        <FormLabel htmlFor="lastname">Last Name:</FormLabel>
        <FormInput
          id="lastname"
          name="lastname"
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(ev) => setLastName(ev.target.value)}
        />

        <FormLabel htmlFor="city">City:</FormLabel>
        <FormInput
          id="city"
          name="city"
          type="text"
          placeholder="City"
          value={city}
          onChange={(ev) => setCity(ev.target.value)}
        />

        <FormLabel htmlFor="email">Email Address:</FormLabel>
        <FormInput
          id="email"
          name="email"
          type="text"
          placeholder="Email"
          value={email}
          disabled
        />

        <div />
        <FormButton
          id="submitBtn"
          onClick={onSubmit}
          disabled={
            city.length === 0 || firstName.length === 0 || lastName.length === 0
          }
        >
          Update Profile
        </FormButton>
      </FormBase>
    </div>
  );
};
