// File name: game.js
// Author: Sam Mulford
// Email: samuel.c.mulford@vanderbilt.edu
// Class: CS4288
// Assignment Number: HW #5
// Description: Solitaire game component
// Honor Statement: I pledge my honor that I have neither
// given nor received unauthorized aid on this assignment
// Last changed: 11/15/2023

"use strict";

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Pile } from "./pile.js";
import { FormButton } from "./shared.js";

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: sb / main;
  background-color: green;
  height: 100vh;
`;

export const Game = (user) => {
  const { id } = useParams();
  let [state, setState] = useState({
    pile1: [],
    pile2: [],
    pile3: [],
    pile4: [],
    pile5: [],
    pile6: [],
    pile7: [],
    stack1: [],
    stack2: [],
    stack3: [],
    stack4: [],
    draw: [],
    discard: [],
  });

  // Selected card
  const [selectedCard, setSelectedCard] = useState(null);
  // Draw 1 or 3
  const [drawType, setDrawType] = useState(null);

  const [hasWon, setHasWon] = useState(null);
  const [hasGameEnded, setHasGameEnded] = useState(null);
  const [hasFoundEndState, setHasFoundEndState] = useState(null);
  const [autoMoveMsg, setAutoMoveMsg] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getGameState = async () => {
      const response = await fetch(`/v1/game/${id}`);

      const data = await response.json();

      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
      setDrawType(data.drawCount);
      setHasGameEnded(!data.active);
      setHasWon(data.won);
      setHasFoundEndState(data.foundEndState);
    };
    getGameState();
  }, [id]);

  /**
   * When a user clicks a card to be moved, it updates state accordingly
   * When a user clicks a pile to move the card, it will call moveCard to execute the move
   *
   * @param pileName The name of the pile clicked
   * @param card     The card that was clicked
   */
  const onCardClick = async (pileName, card) => {
    card.stopPropagation();
    // If a card is already clicked, move that card to the pile
    if (selectedCard) {
      await moveCard(selectedCard.pile, pileName, selectedCard.card);
      setSelectedCard(null);
    } else if (
      state[pileName].length !== 0 &&
      !card.target.src.includes("face_down.jpg")
    ) {
      // Setting the selected card, but only if the pile is not empty and the card is not face-down
      setSelectedCard({
        pile: pileName,
        card: card.target.id,
      });
    }
  };

  /**
   * When someone clicks the talon, it will put either 1 or 3 cards into the waste
   * If the talon is empty and someone clicks it, it will bring the cards from the waste
   * back to the talon
   *
   * @param ev Click event
   */
  const onDeckClick = async (ev) => {
    ev.stopPropagation();

    let newDraw, newDiscard, putData;
    if (state.draw.length !== 0) {
      let cards = [];

      // Get cards to be moved for Draw 1 or Draw 3
      if (drawType === 1) {
        cards.push(
          JSON.parse(JSON.stringify(state.draw[state.draw.length - 1])),
        );
      } else {
        // Draw 3
        // Get top 3 cards if they exist, will only use 1 if it is Draw 1
        let startIndex = Math.max(0, state.draw.length - 3);
        let topCards = state.draw.slice(startIndex);
        cards = JSON.parse(JSON.stringify(topCards));
      }

      putData = {
        cards: cards,
        src: "draw",
        dst: "discard",
      };
    } else {
      // When the talon is empty, let's refill it
      newDraw = JSON.parse(JSON.stringify(state.discard));
      newDraw.forEach((card) => (card.up = false));
      newDraw.reverse();

      putData = {
        cards: newDraw,
        src: "discard",
        dst: "draw",
      };
    }

    // Request to move
    console.log(putData);
    await putMove(putData);
  };

  /**
   * Someone can "unclick" their card by clicking the background
   */
  const onBackgroundClick = () => {
    setSelectedCard(null);
  };

  /**
   * Will move card(s) from fromPileName to toPileName
   *
   * @param fromPileName The original pile where card is
   * @param toPileName   The pile where card is to be moved
   * @param card         The card to be moved (might take some other cards with it)
   */
  const moveCard = async (fromPileName, toPileName, card) => {
    // Cannot move a card to its pile && cannot move a pile to discard
    if (fromPileName === toPileName || toPileName === "discard") {
      setSelectedCard(null);
      return;
    }

    // Get index of the selected card
    const splitCard = card.split(":");
    const selectionIndex = state[fromPileName].findIndex(
      (card) => card.suit === splitCard[0] && card.value === splitCard[1],
    );

    // Only allow the bottom card to move from the piles to the stack
    if (
      toPileName === "stack1" ||
      toPileName === "stack2" ||
      toPileName === "stack3" ||
      toPileName === "stack4"
    ) {
      if (selectionIndex !== state[fromPileName].length - 1) {
        return;
      }
    }

    // Get moved cards
    let movedCards = [];
    for (let i = selectionIndex; i < state[fromPileName].length; ++i) {
      movedCards.push(JSON.parse(JSON.stringify(state[fromPileName][i])));
    }

    let putData = {
      cards: movedCards,
      src: fromPileName,
      dst: toPileName,
    };

    // Request to move
    console.log(putData);
    await putMove(putData);
  };

  /**
   * When a move has been determined, we send a request to execute
   * the move to the backend
   *
   * @param putData The move to be executed
   */
  const putMove = async (putData) => {
    try {
      const response = await fetch(`/v1/game/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(putData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error moving:", errorData.error);
      } else {
        const responseData = await response.json();

        if (response.status === 201) {
          setHasWon(true);
        } else if (response.status === 202) {
          setHasFoundEndState(true);
        } else {
          setHasFoundEndState(false);
        }

        setState(responseData);
        return responseData;
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const autoMove = async () => {
    let autMoveCounter = 0;
    let autMove = await findAutoMove(state);
    let didAutoMove = false;

    while (autMove !== false && autMoveCounter < 3) {
      didAutoMove = true;
      let newState = await putMove(autMove);
      console.log(autMove);
      autMove = await findAutoMove(newState);
    }

    let autoMsg = "";
    if (didAutoMove) {
      autoMsg = "All automoves complete.";
    } else {
      autoMsg =
        "Could not find any automoves. No direct moves from the piles/discard to the stacks available.";
    }
    setAutoMoveMsg(autoMsg);
  };

  // If an auto move is possible, return the move, else return false
  const findAutoMove = async (newState) => {
    if (!newState) return false;

    const pileKeys = [
      "pile1",
      "pile2",
      "pile3",
      "pile4",
      "pile5",
      "pile6",
      "pile7",
    ];

    for (let pileKey of pileKeys) {
      const pile = newState[pileKey];

      if (pile && pile.length > 0) {
        const bottomCard = pile[pile.length - 1]; // Select the bottom face-up card
        for (let stackKey of ["stack1", "stack2", "stack3", "stack4"]) {
          const stack = newState[stackKey];
          if (canPlaceOnStack(bottomCard, stack, isInOrder)) {
            let cardz = [];
            cardz.push(bottomCard);
            return {
              cards: cardz,
              src: pileKey,
              dst: stackKey,
            };
          }
        }
      }
    }

    if (newState.discard && newState.discard.length > 0) {
      const topDiscardCard = newState.discard[newState.discard.length - 1];

      // Check each stack to see if the top discard card can be moved to it
      for (let stackKey of ["stack1", "stack2", "stack3", "stack4"]) {
        const stack = newState[stackKey];
        if (canPlaceOnStack(topDiscardCard, stack, isInOrder)) {
          let cardz = [];
          cardz.push(topDiscardCard);
          return {
            cards: cardz,
            src: "discard",
            dst: stackKey,
          };
        }
      }
    }

    return false;
  };

  const canPlaceOnStack = (card, stack, isInOrderFunc) => {
    if (stack.length === 0 && card.value === "ace") {
      return true; // Aces can start a stack
    } else if (stack.length === 0) return false;

    const topCard = stack[stack.length - 1];
    return card.suit === topCard.suit && isInOrderFunc(topCard, card);
  };

  // Return true if card2 follows card1
  function isInOrder(card1, card2) {
    const order = [
      "ace",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "jack",
      "queen",
      "king",
    ];

    let index1 = order.indexOf(card1.value);
    let index2 = order.indexOf(card2.value);
    return index1 + 1 === index2;
  }

  const handleEndGame = async () => {
    try {
      const response = await fetch(`/v1/game/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          active: false,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error ending game:", errorData.error);
      } else {
        setHasGameEnded(true);
        navigate("/results/" + id);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <GameBase onClick={onBackgroundClick}>
      <div>
        <FormButton
          id="submitBtn"
          style={{
            backgroundColor: "lightgray",
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "10px",
          }}
          onClick={autoMove}
        >
          Autocomplete
        </FormButton>
        <p
          style={{
            display: autoMoveMsg ? "inline-block" : "none",
            color: "lightgray",
            marginLeft: "10px",
          }}
        >
          {autoMoveMsg}
        </p>
      </div>
      <CardRow>
        {/*stacks 1 - 4*/}
        <Pile
          cards={state.stack1}
          spacing={0}
          onClick={(card) => onCardClick("stack1", card)}
        />
        <Pile
          cards={state.stack2}
          spacing={0}
          onClick={(card) => onCardClick("stack2", card)}
        />
        <Pile
          cards={state.stack3}
          spacing={0}
          onClick={(card) => onCardClick("stack3", card)}
        />
        <Pile
          cards={state.stack4}
          spacing={0}
          onClick={(card) => onCardClick("stack4", card)}
        />
        <CardRowGap />
        {/*deck & waste*/}
        <Pile
          cards={state.draw}
          spacing={0}
          onClick={(ev) => onDeckClick(ev)}
        />{" "}
        {/*deck*/}
        <Pile
          cards={state.discard}
          spacing={0}
          onClick={(card) => onCardClick("discard", card)}
        />{" "}
        {/*waste*/}
      </CardRow>
      {state.draw !== undefined &&
        state.discard !== undefined &&
        state.draw.length === 0 &&
        state.discard.length !== 0 && (
          <p style={{ textAlign: "right", color: "white" }}>
            Click on the empty draw pile to refresh
          </p>
        )}
      <CardRow>
        {/*tableau 1 - 7*/}
        <Pile
          cards={state.pile1}
          onClick={(card) => onCardClick("pile1", card)}
        />
        <Pile
          cards={state.pile2}
          onClick={(card) => onCardClick("pile2", card)}
        />
        <Pile
          cards={state.pile3}
          onClick={(card) => onCardClick("pile3", card)}
        />
        <Pile
          cards={state.pile4}
          onClick={(card) => onCardClick("pile4", card)}
        />
        <Pile
          cards={state.pile5}
          onClick={(card) => onCardClick("pile5", card)}
        />
        <Pile
          cards={state.pile6}
          onClick={(card) => onCardClick("pile6", card)}
        />
        <Pile
          cards={state.pile7}
          onClick={(card) => onCardClick("pile7", card)}
        />
      </CardRow>

      <div style={{ backgroundColor: "green" }}>
        <h1
          style={{
            marginTop: "250px",
            color: "lightgray",
            textAlign: "center",
            display: hasWon ? "block" : "none",
          }}
        >
          YOU HAVE WON THE GAME!
        </h1>
        <h3
          style={{
            marginTop: "250px",
            color: "lightgray",
            textAlign: "center",
            display:
              !hasWon && hasFoundEndState && !hasGameEnded ? "block" : "none",
          }}
        >
          No direct moves from piles/discard to the stacks! End game?
        </h3>
        <FormButton
          id="submitBtn"
          style={{
            backgroundColor: "lightgray",
            marginLeft: "43%",
            display:
              (hasWon && !hasGameEnded) || (hasFoundEndState && !hasGameEnded)
                ? "block"
                : "none",
          }}
          onClick={handleEndGame}
        >
          End Game?
        </FormButton>
      </div>
    </GameBase>
  );
};

Game.propTypes = {};
