// File name: logout.js
// Author: Sam Mulford
// Email: samuel.c.mulford@vanderbilt.edu
// Class: CS4288
// Assignment Number: HW #5
// Description: Solitaire logout component
// Honor Statement: I pledge my honor that I have neither
// given nor received unauthorized aid on this assignment
// Last changed: 11/15/2023

"use strict";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

export const Logout = ({ logOut }) => {
  let navigate = useNavigate();
  useEffect(() => {
    // Log out the actual user - i.e. clear user data
    deleteSession();
    logOut();
    navigate(`/login`);
  });
  return <></>;
};

/**
 * Makes a request to delete the user session
 */
const deleteSession = async () => {
  try {
    const response = await fetch(`/v1/session`, {
      method: "DELETE",
    });

    if (response.ok) {
      console.log("Session deleted successfully");
    } else {
      console.error("Server error:", response.status, response.statusText);
    }
  } catch (error) {
    // Handle network errors
    console.error("Network error:", error);
  }
};

Logout.propTypes = {
  logOut: PropTypes.func.isRequired,
};
